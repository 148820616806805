import { BaseModel }	from "./BaseModel";
import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";
import { Score }					from "./Score";

// =============================================================================
export class Step extends BaseModel {
	dispStatus:		string;
	dispDate:		Date;
	step:			string;
	stepDate:		Date;
	applicantId:	number;
	opportunityId:	number;

	// =========================================================================
	public unpack(data: any) {
		Object.assign(this, data);

		this.stepDate = new Date(this.stepDate);
	}
}
