import { BaseModel }			from "./BaseModel";
import { Category }				from "./Category";
import { Component, Inject }	from "@angular/core";
import { HttpClient }			from "@angular/common/http";

// =============================================================================
export class Position extends BaseModel {
	id:				number;
	jobCode:		string;
	positionText:	string;
	categories:		Category[];

	dirty:			boolean = false;

	// =========================================================================
	static fetchMany(http: HttpClient) {
		return http.get<Position[]>("/api/position");
	}

	// =========================================================================
	public unpack(data: any) {
		Object.assign(this, data);

		if(this.categories) {
			this.categories.forEach((c, i) => this.categories[i] = new Category(c));
		}
		else {
			this.categories = [];
		}
	}
}
