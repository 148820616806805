import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "PhonePipe"
})
export class PhonePipe implements PipeTransform {
  transform(raw: string): string {
	if(raw && 10 == raw.length) {
		return	raw.slice(0, 3)
				+ "-"
				+ raw.slice(3, 6)
				+ "-"
				+ raw.slice(6)
		;
	}
   return raw;
  }

}
