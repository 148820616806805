import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	title = 'Structured Interview';
  isIframe = window !== window.parent && !window.opener;

	constructor() {
	}

	ngOnInit() {
		// Hack my way to the container Angular created and make it fluid, to avoid
		// wasting screen real estate.
		let containers: HTMLCollection = document.getElementsByClassName("container");
		containers[0].className = "container-fluid"
	}
}
