import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";
import { Interviewer }				from "../models/Interviewer";

// =========================================================================
export class AdminHomeView {
	public interviewers:	Interviewer[];

	// =========================================================================
	construct() {
		this.interviewers = new Array();
	}

	// =========================================================================
	public fetch(http: HttpClient) {
		let promise = http.get<Interviewer[]>("/view/adminhome")

		promise.subscribe(
			result => {
				this.interviewers = new Array();

				result.forEach(intPlain => {
					this.interviewers.push(new Interviewer(intPlain));
				});
			},
			error => {
				alert("System error - contact your administrator.");
			}
		);

		return promise;
	}
}
