import { Component, Inject }		from "@angular/core";
import { HttpClient, HttpParams }	from "@angular/common/http";
import { Answer }					from "../models/Answer";
import { Applicant }				from "../models/Applicant";
import { Category }					from "../models/Category";
import { Observable }				from "rxjs";
import { Opportunity }				from "../models/Opportunity";
import { Score }					from "../models/Score";
import { Step }						from "../models/Step";
import { tap }						from "rxjs/operators";

// =========================================================================
export class InterviewInputView {
	static minScore: number = 9;

	public id:				number = null;
	public applicantId:		number = null;
	public applicants:		Applicant[];
	public opportunityId:	number = null;
	public scores:			Score[];
	totalScore:				number = null;
	status:					string = null;
	currentStep:			Step;

	private okToSave:	boolean = true;
	public saving:		boolean	= false;

	// =========================================================================
	public constructor(opp: Opportunity, app: Applicant) {
		if(null !== app) {
			this.applicantId = app.id;
		}

		if(null == opp) {
			this.scores = new Array<Score>();
		}
		else {
			this.opportunityId = opp.id;

			this.scores = new Array<Score>();

			if(opp.position.categories) {
				opp.position.categories.forEach(cat => {
					let score = new Score();
					score.answers = new Array<Answer>();

					cat.questions.forEach(q => {
						score.answers.push(new Answer());
					});

					this.scores.push(score);
				});
			}
		}

		this.currentStep = new Step();
	}

	// =========================================================================
	public canHire() {
		return this.isScored() && InterviewInputView.minScore < this.totalScore;
	}

	// =========================================================================
	public declineOnly() {
		return this.isScored() && InterviewInputView.minScore >= this.totalScore;
	}

	// =========================================================================
	public fetch(http: HttpClient, appId: number, oppId: number) {
		let self = this;

		let promise = http.get<InterviewInputView>("/view/interviewinput", {
			params: {
				applicantid:	appId.toString(),
				opportunityid:	oppId.toString(),
			},
		});

		return promise.pipe(tap(
			result => {
				self.unpack(result);
				this.totalScore = this.score();
			},
			error => {
				console.error(error);

				alert("System error - contact your administrator.");
			}
		));
	}

	// =========================================================================
	public isInterview() {
		return null !== this.status &&
			"interview" == this.status.toLowerCase()
		;
	}

	// =========================================================================
	public isPreInterview() {
		return null !== this.status &&
			"conduct interview" == this.status.toLowerCase()
		;
	}

	// =========================================================================
	public isScored() {
		return null !== this.status &&
			"selection/scoring" == this.status.toLowerCase()
		;
	}

	// =========================================================================
	public isTerminal() {
		if(null === this.status) {
			return false;
		}

		switch(this.status.toLowerCase()) {
			case "declined by candidate":	return true;
			case "hire":					return true;
			case "sip decline":				return true;
			case "sip offer":				return true;
			default:						return false;
		}
	}

	// =========================================================================
	public pack() {
		let r = [];

		this.scores.forEach(score => {
			let saver = Object.assign(score);

			delete saver.category;

			r.push(saver);
		});

		return r;
	}

	// =========================================================================
	public setStatus(http: HttpClient, status: string) {
		let params = new HttpParams()
			.set("applicantid", this.applicantId.toString())
			.set("opportunityid", this.opportunityId.toString())
			.set("status", status)
		;

		let promise = http.patch<any>("/view/interviewinput", {}, {
			params: params
		});

		promise.subscribe(
			resp => {
				this.status = resp.status;
			},
			error => {
				console.log("Finalize error = ...");
				console.log(error);
				alert("Communication error");
			}
		);
	}

	// =========================================================================
	public readOnly() {
		switch(this.status) {
			case "Interview":			return false;
			case "Conduct Interview":	return true;
			default:					return true;
		}
	}

	// =========================================================================
	public save(http: HttpClient) {
		let self = this;

		// If this is the first save, give it time to create a record
		if(0 == this.id) {
			this.okToSave = false;
			setTimeout(() => this.okToSave = true, 2000);
		}

		if(this.okToSave) {
			this.saving = true;
			this.totalScore = this.score();

			this.scores.forEach(s => delete s.category);

			let promise = http.put<InterviewInputView>("/view/interviewinput",  this);

			return promise.pipe(tap(
				result => {
					self.unpack(result);
					self.saving = false;
				},
				error => {
					console.error(error);

					alert("System error - contact your administrator.");
					self.saving = false;
				}
			));
		}
		else {
			return Observable.create(observer => observer.next(""));
		}
	}

	// =========================================================================
	public score(): number {
		let totalScore = 0;

		if(undefined !== this.scores) {
			this.scores.forEach(score => {
				if(null !== score) {
					totalScore += score.scoreValue;
				}
			});
		}

		return totalScore;
	}

	// =========================================================================
	public statusText() {
		if(null === this.status) {
			return null;
		}
		else {
			switch(this.status.toLowerCase()) {
				case "declined by candidate":	return "Candidate Declined";
				default:						return this.status;
			}
		}
	}

	// =========================================================================
	private unpack(data: any) {
		Object.assign(this, data);

		this.scores.forEach((s, i) => {
			this.scores[i] = new Score(s);
		});
	}
}
