import { HttpClient } 		from	"@angular/common/http";
import { Injectable } 		from	"@angular/core";
import { pipe, Subject }	from	"rxjs";
import { tap }				from	"rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class UserInfoService {
			authenticationType:	string	| null = null;
	public	beingAdmin:			boolean = false;
			email:				string	| null = null;
			isAuthenticated:	boolean	| null = null;
			role:				string	| null = null;

			http:				HttpClient;

			private				announceFilter = new Subject<boolean>();
			public				announceFilter$ = this.announceFilter.asObservable();

	// =========================================================================
	constructor(http: HttpClient) {
		this.http = http;
	}

	// =========================================================================
	beAdmin(yesno: boolean) {
		if(this.isHRBP()) {
			this.beingAdmin = yesno;

			this.announceFilter.next(yesno);
		}
	}

	// =========================================================================
	fetch() {
		let self = this;
		let obsrv = this.http.get("/api/userinfo");

		return obsrv.pipe(tap(
			resp => {
				Object.assign(self, resp);
			},
			error => {
				console.log("Userinfo error:");
				console.log(error);

				alert("Communication error");
			}
		));
	}

	// =========================================================================
	public isAdmin() {
		return this.beingAdmin ||
			(null !== this.role && 'admin' == this.role.toLowerCase())
		;
	}

	// =========================================================================
	public isHRBP() {
		return null !== this.role && 'hrbp' == this.role.toLowerCase();
	}

	// =========================================================================
	public isHRM() {
		return null !== this.role && 'hrm' == this.role.toLowerCase();
	}
}
