import { Applicant }								from "../models/Applicant";
import { BsModalRef, BsModalService }				from "ngx-bootstrap/modal";
import { Category }									from "../models/Category";
import { Component, Inject, Input, NgModule, TemplateRef }	from "@angular/core";
import { DatePipe }									from "@angular/common";
import { HttpClient }								from "@angular/common/http";
import { InterviewCategoryView }					from "../views/InterviewCategoryView";
import { InterviewInputView }						from "../views/InterviewInputView";
import { InterviewView }							from "../views/InterviewView";
import { Interviewer }								from "../models/Interviewer";
import { Opportunity }								from "../models/Opportunity";
import { Question }									from "../models/Question";
import { Router, ActivatedRoute, ParamMap }			from "@angular/router";
import { UserInfoService }							from "../services/user-info.service";
import { faCaretRight, faAngleDoubleRight, faStar, faTools }	from "@fortawesome/free-solid-svg-icons";

// =============================================================================
@Component({
	providers: [ DatePipe ],
	selector:	"interview",
	templateUrl:	"./interview.component.html",
	styleUrls:	[ "./interview.component.css" ],
})

// =============================================================================
export class InterviewComponent {
	private route;

	public currentAppId = 0;
	public currentOppId = 0;

	public currentApplicant = new Applicant();
	public currentInput = new InterviewInputView(null, null);
	public currentOpportunity = new Opportunity();

	public loading = false;

	public decision = null;
	public decisionText = {
		"SIP Decline":	"decline to hire",
		"SIP Offer":	"make an offer to",
	};

	public adminModalRef: BsModalRef;
	public adminModalId = 1;
	public adminTooltipText = "Click here to unlock this interview";

	public decideModalRef: BsModalRef;
	public decideModalId = 2;

	public finalizeModalRef: BsModalRef;
	public finalizeModalId = 3;

	public myModel = new InterviewView();

	public okToFinalize = false;

	public faStar = faStar;
	public faAngleDoubleRight = faAngleDoubleRight;
	public faCaretRight = faCaretRight;
	public faTools = faTools;

	public starsArray = [ 1, 2, 3 ];

	@Input() interviewer: string;

	// =========================================================================
	constructor(
		public http: HttpClient,
		route: ActivatedRoute,
		private modalService: BsModalService,
		public userInfo: UserInfoService,
		private router: Router
	) {
		this.route = route;
	}

	// =========================================================================
	public answerChange(catIndex, questionIndex) {
		if(!this.enableStars(catIndex)) {
			this.currentInput.scores[catIndex].scoreValue = 0;
		}

		this.saveInterview();
	}

	// =========================================================================
	public beginInterview() {
		this.currentInput.save(this.http)
		.subscribe(resp => {
			this.currentInput.setStatus(this.http, "Interview");
		});
	}

	// =========================================================================
	public closeAdminModal() {
		this.modalService.hide(this.adminModalId);
	}

	// =========================================================================
	public closeDecideModal() {
		this.modalService.hide(this.decideModalId);
	}

	// =========================================================================
	public closeFinalizeModal() {
		this.modalService.hide(this.finalizeModalId);
	}

	// =========================================================================
	public decide(decision: string) {
		this.currentInput.setStatus(this.http, decision);
		this.closeDecideModal();

		return false;
	}

	// =========================================================================
	public disableAnswer(catIndex, questionIndex) {
		// All disabled 'til we pick a candidate
		if(undefined === this.currentInput.scores[catIndex]) {
			return true;
		}
		else if(0 == this.currentAppId) {
			return true;
		}
		else if(this.currentInput.readOnly()) {
			return true;
		}
		else {
			var hasAnswer = null;

			this.currentInput.scores[catIndex].answers.forEach((a, i) => {
				if(undefined !== a.answerText && null !== a.answerText && 0 < a.answerText.length) {
					hasAnswer = i;
				}
			});

			if(null == hasAnswer) {
				return false;
			}
			else {
				return questionIndex != hasAnswer;
			}
		}

	}

	// =========================================================================
	public enableStars(catIndex) {
		let enable = false;

		if(false == this.currentOpportunity.position.categories[catIndex].scorable) {
			return false;
		}

		if(undefined !== this.currentInput.scores[catIndex]) {
			// If this category has no questions, see if there are any answers at all
			if(0 == this.currentOpportunity.position.categories[catIndex].questions.length) {
				this.currentInput.scores.forEach(s => {
					s.answers.forEach(a => {
						if(undefined !== a.answerText && null !== a.answerText && 0 < a.answerText.length) {
							enable = true;
						}
					});
				});
			}

			this.currentInput.scores[catIndex].answers.forEach(a => {
				if(undefined !== a.answerText && null !== a.answerText && 0 < a.answerText.length) {
					enable = true;
				}
			});
		}

		return enable;
	}

	// =========================================================================
	public finalize() {
		this.currentInput.setStatus(this.http, "Selection/Scoring");
		this.closeFinalizeModal();
	}

	// =========================================================================
	public interviewing() {
		return "Interview" == this.currentInput.status;
	}

	// =========================================================================
	public navBack() {
		this.router.navigate([ "/home" ], {
			queryParams: {
				reqNumber:	this.currentOpportunity.reqNumber
			}
		});
	}

	// =========================================================================
	public ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.currentAppId = params.appid;
			this.currentOppId = params.oppid;
			this.interviewer = params.interviewer;
		});

		this.userInfo.fetch()
		.subscribe(resp => {
			if(!this.userInfo.isAdmin()) {
				this.adminTooltipText = "";
			}

			this.currentApplicant.fetch(this.http, this.currentAppId)
			.subscribe(
				resp => {
					this.currentOpportunity.fetch(this.http, this.currentOppId)
					.subscribe(
						resp => {
							this.currentInput = new InterviewInputView(
								this.currentOpportunity, this.currentApplicant
							);

							this.currentInput.fetch(this.http, this.currentAppId, this.currentOppId)
							.subscribe(resp => {
								this.setOkToFinalize()

								// Fetch categories & scores for this opportunity
								let catView = new InterviewCategoryView(this.currentOppId);

								catView.fetch(this.http)
								.subscribe(response => {
									this.currentOpportunity.position.categories = catView.categories;
								});
							});
						}
					);

				}
			);
		});
	}

	// =========================================================================
	public openAdminModal(template: TemplateRef<any>) {
		if(this.userInfo.isAdmin()) {
			this.decideModalRef = this.modalService.show(template, {
				id: this.adminModalId,
				class: "modal-lg"
			});
		}

		return false;
	}

	// =========================================================================
	public openDecideModal(template: TemplateRef<any>, decision) {
		this.decision = decision;

		this.decideModalRef = this.modalService.show(template, {
			id: this.decideModalId,
			class: "modal-lg"
		});

		return false;
	}

	// =========================================================================
	public openFinalizeModal(template: TemplateRef<any>) {
		this.finalizeModalRef = this.modalService.show(template, {
			id: this.finalizeModalId,
			class: "modal-lg"
		});

		return false;
	}

	// =========================================================================
	public setOkToFinalize() {
		let ok = true;

		this.currentInput.scores.forEach(score => {
			if(score.scorable) {
				ok = ok && 0 < score.scoreValue;

				let hasAnswers = 0 == score.answers.length;

				score.answers.forEach(answer => {
					hasAnswers = hasAnswers || (answer.answerText && 0 < answer.answerText.length);
				});

				ok = ok && hasAnswers;
			}
		});

		this.okToFinalize = ok;
	}

	// =========================================================================
	public saveInterview() {
		this.currentInput.save(this.http)
		.subscribe(r => {
			this.setOkToFinalize()
		});
	}

	// =========================================================================
	public setStars(catIndex, stars) {
		if(!this.currentInput.readOnly() && this.enableStars(catIndex)) {
			this.currentInput.scores[catIndex].scoreValue = stars;
			this.saveInterview();
		}
	}

	// =========================================================================
	// Should this star shine?
	public shine(catIndex, stars) {
		return stars <= this.starsFor(catIndex);
	}

	// =========================================================================
	get showQuestions() {
		return null !== this.currentOpportunity.id;
	}

	// =========================================================================
	public starsFor(catIndex) {
		if(this.currentInput.scores[catIndex]) {
			return this.currentInput.scores[catIndex].scoreValue;
		}
		else {
			return false;
		}
	}

	// =========================================================================
	public unlock() {
		this.currentInput.setStatus(this.http, "Interview");
		this.closeAdminModal();
	}
}
