import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";
import { Interviewer }				from "../models/Interviewer";
import { tap }						from "rxjs/operators";

// =========================================================================
export class HRBPHomeView {
	public interviewers:	Interviewer[];

	// =========================================================================
	construct() {
		this.interviewers = new Array();
	}

	// =========================================================================
	public fetch(http: HttpClient, email: string = null) {
		let promise = null;

		if(null == email) {
			promise = http.get<Interviewer[]>("/view/hrbphome");
		}
		else {
			promise = http.get<Interviewer[]>("/view/hrbphome", {
				params: {
					email:	email
				}
			});
		}

		return promise.pipe(tap(
			result => {
				let data = result as any[];

				this.interviewers = new Array();

				data.forEach(intPlain => {
					this.interviewers.push(new Interviewer(intPlain));
				});
			},
			error => {
				console.error(error);

				alert("System error - contact your administrator.");
			}
		));
	}
}
