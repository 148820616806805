import { Component, Inject }	from "@angular/core";
import { HttpClient }			from "@angular/common/http";
import { Interviewer }			from "../models/Interviewer";

// =========================================================================
export class HRMsView {
	public hrms:	Interviewer[];

	// =========================================================================
	construct() {
		this.hrms = new Array();
	}

	// =========================================================================
	public fetch(http: HttpClient, email: string) {
		let params = {
			params: {
				email:	email,
			}
		};

		let promise = http.get<Interviewer[]>("/view/hrms", params);

		promise.subscribe(
			result => {
				this.hrms = new Array();

				result.forEach(intPlain => {
					this.hrms.push(new Interviewer(intPlain));
				});
			},
			error => {
				console.error(error);

				alert("System error - contact your administrator.");
			}
		);

		return promise;
	}
}
