import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";
import { Category }					from "../models/Category";
import { Question }					from "../models/Question";
import { tap }						from "rxjs/operators";

// =========================================================================
export class InterviewCategoryView {
	public oppid:		number;
	public categories:	Category[];

	// =========================================================================
	public constructor(oppid: number) {
		if(null == oppid) {
			throw("CategoryInputView constructor requires oppid");
		}

		this.oppid = oppid;
	}

	// =========================================================================
	public fetch(http: HttpClient) {
		let self = this;

		let obsrv = http.get("/view/interviewcategories", {
			params: {
				oppid:	this.oppid.toString(),
			},
			responseType:	"json",
		});

		return obsrv.pipe(tap(
			result => {
				self.categories = [];

				Object.values(result).forEach(cat => {
					let newCat = new Category();
					newCat.unpack(cat);

					self.categories.push(newCat);
				});			
			},
			error => {
				console.error(error);

				alert("System error - contact your administrator.");
			}
		));
	}

	// =========================================================================
	private unpack(data: any) {
		Object.assign(this, data);
	}
}
