import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";
import { Answer }					from "../models/Answer";
import { Applicant }				from "../models/Applicant";
import { Category }					from "../models/Category";
import { Opportunity }				from "../models/Opportunity";
import { Position }					from "../models/Position";
import { Score }					from "../models/Score";
import { Step }						from "../models/Step";
import { tap }						from "rxjs/operators";

// =========================================================================
export class HRMHomeView {
	public opportunities:	Opportunity[];

	// =========================================================================
	construct() {
		this.opportunities = new Array();
	}

	// =========================================================================
	public fetch(http: HttpClient, email?: string, hrbp?: string) {
		let params:{ [key: string]: string } = {};

		if(!!email) {
			params.interviewer =	email;
		}
    if(!!hrbp) {
			params.hrbp =	hrbp;
		}

		let promise = http.get<Opportunity[]>("/view/home", { params });

		return promise.pipe(tap(
			result => {
				this.opportunities = new Array();

				result.forEach(oppPlain => {
					this.opportunities.push(new Opportunity(oppPlain));
				});
			},
			error => {
				console.error(error);

				alert("System error - contact your administrator.");
			}
		));
	}
}
