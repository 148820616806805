import { BaseModel }				from "./BaseModel";
import { Component, Inject }		from "@angular/core";
import { HttpClient, HttpParams }	from "@angular/common/http";
import { Position }					from "../models/Position";
import { Question }					from "./Question";
import { tap }						from "rxjs/operators";

// =============================================================================
export class Category extends BaseModel {
	id:					number = 0;
	categoryText:		string = null;
	sortOrder:			number = 0;
	deleted:			boolean = false;
	jobCategoryId:		number = 0;
	questions:			Question[];
	showDeleted:		boolean = false;
	scorable:			boolean = false;

	// =========================================================================
	static clone(http: HttpClient, jobCatId: number, newJobCatId: number) {
		let obsrv = http.get<Category[]>("/api/category/clone", {
			params: {
				jobcatid:	jobCatId.toString()
			}
		});

		console.log("Category.clone:");
		console.log("jobCatId = " + jobCatId);
		console.log("newJobCatId = " + newJobCatId);

		return obsrv.pipe(tap(
			result => {
				let categories = [];

				result.forEach((r, i) => {
					result[i] = new Category();
					result[i].unpack(r);
					result[i].jobCategoryId = newJobCatId;
				});
			},
			error => {
				console.error(error);
				alert("System error - contact your administrator");
			}
		));
	}

	// =========================================================================
	static fetchMany(http: HttpClient, jobCatId: number) {
		let obsrv = http.get<Category[]>("/api/category", {
			params: {
				jobcatid:	jobCatId.toString()
			}
		});

		return obsrv.pipe(tap(
			result => {
				result.forEach((r, i) => r[i] = new Category(r[i]));
			},
			error => {
				console.error(error);
				alert("System error - contact your administrator");
			}
		));
	}

	// =========================================================================
	static pack(cat, jobCatId): Category {
		let newCat = new Category();

		Object.assign(newCat, cat);

		newCat.id = newCat.id ? newCat.id : -1;

		newCat.jobCategoryId = jobCatId;
		newCat.deleted = newCat.deleted ? true : false;

		newCat.questions = [];

		cat.questions.forEach(q => {
			newCat.questions.push(Question.pack(q, newCat.id));
		});

		return newCat;
	}

	// =========================================================================
	static saveMany(
		http: HttpClient,
		jobCatId: number,
		categories: Category[]
	) {
		let payload: Category[] = [];

		categories.forEach(c => {
			payload.push(Category.pack(c, jobCatId));
		});

		console.log("Category.saveMany ...");
		console.log(payload);

		return http.post<Category[]>("/api/category", payload, {
			params: {
				jobcatid:	jobCatId.toString()
			}
		});
	}

	// =========================================================================
	public unpack(data: any) {
		Object.assign(this, data);

		if(data.questions) {
			data.questions.forEach((q, i) => {
				this.questions[i] = new Question();
				this.questions[i].unpack(q);
			});
		}
		else {
			this.questions = [];
		}
	}
}
