import { BaseModel }	from "./BaseModel";
import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";
import { Applicant }				from "./Applicant";
import { Position }					from "./Position";
import { Score }					from "./Score";
import { Step }						from "./Step";

// =============================================================================
export class Interview extends BaseModel {
	applicantId:	number;
	closedAt:		Date;
	interviewedAt:	Date;
	opportunityId:	number;
	scores:			Score[];

	// =========================================================================
	unpack(data: any) {
		Object.assign(this, data);

		if(this.scores) {
			this.scores.forEach((s, i) => this.scores[i] = new Score(s));
		}
		else {
			this.scores = [];
		}

		this.closedAt = new Date(this.closedAt);
		this.interviewedAt = new Date(this.interviewedAt);
	}
}
