import { AccordionService }				from "../services/accordion.service";
import { Component, Input, OnInit }		from "@angular/core";
import { faCalendar, faCalendarTimes }	from "@fortawesome/free-solid-svg-icons";
import { HRMHomeView }					from "../views/HRMHomeView";
import { HttpClient }					from "@angular/common/http";
import { Interviewer }					from "../models/Interviewer";
import { Observable, Subscription }					from "rxjs";
import { Opportunity } from "../models/Opportunity";

// =============================================================================
@Component({
	selector: "hrm-panel",
	templateUrl: "./hrm-panel.component.html",
	styleUrls: ["./hrm-panel.component.css"]
})
export class HRMPanelComponent implements OnInit {
	public flashing:			boolean = false;
	public homeView:			HRMHomeView;
	public http: 				HttpClient;
	public numOpportunities:	number;
  public opportunities: Opportunity[];

	public isCollapsed:			boolean = true;

	@Input() filter:		boolean = false;
	@Input() hrm:			Interviewer;
  @Input() hrbp:			Interviewer;
	@Input() panelIndex:	number;

	public faCalendar = faCalendar;
	public faCalendarTimes = faCalendarTimes;

	private accordionService: AccordionService;
	private accordionSubscription: Subscription;

	// =========================================================================
	constructor(
		http: HttpClient,
		accordionService: AccordionService
	) {
		this.http = http;

		this.numOpportunities = -1;
    this.opportunities = [];
		this.homeView = new HRMHomeView();

		this.accordionService = accordionService;
	}

	// =========================================================================
	ngOnDesctroy() {
		this.accordionSubscription.unsubscribe();
	}

	// =========================================================================
	ngOnInit() {
		this.accordionSubscription = this.accordionService.announceFilter$.subscribe(
			reqPath => {
				if(this.accordionService.HRM == this.hrm.email) {
					this.isCollapsed = false;
				}
			}
		);
	}

	// =========================================================================
	flash() {
		let interval = 100;

		this.flashing = true;

		let flasher = setTimeout(() => {
			this.flashing = false;
		}, interval);
	}

	// =========================================================================
	getOpportunities() {
		this.flash();

		if(this.isCollapsed && 0 >= this.numOpportunities) {
			this.homeView.fetch(this.http, this.hrm.email, this.hrbp.email)
			.subscribe(
				resp => {
          this.opportunities = resp.map(o => new Opportunity(o));
					this.numOpportunities = resp.length;
				},
				error => {
					console.log("HRMHomeView.fetch error:");
					console.log(error);
				}
			);
		}

		this.isCollapsed = !this.isCollapsed;
	}

	// =========================================================================
	reqsText(reqs, openFTE) {
		return reqs.toString() +
			(1 == reqs ? " Req  / " : " Reqs / ")
			+ openFTE.toString() +
			(1 == openFTE ? " Open FTE " : " Open FTEs")
		;
	}
}
