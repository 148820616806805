import { Component } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { HttpConfigInterceptor } from "../interceptor/httpconfig.interceptor";
import { Subscription } from "rxjs";
import { UserInfoService } from "../services/user-info.service";
import { MsalService } from '@azure/msal-angular';

@Component({
	selector: 'nav-menu',
	templateUrl: './nav-menu.component.html',
	styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
	public isExpanded = false;
	public showAll = false;
	public userEmail = null;
	public userType = null;

	public userSubscription: Subscription;

	constructor(public userInfo: UserInfoService, private authService: MsalService) {
		this.userSubscription = userInfo.announceFilter$.subscribe(
			userEvent => {
				console.log("nav-menu got a user event:");
				console.log(userEvent);
			}
		);
	}

	collapse() {
	  this.isExpanded = false;
	}

	toggle() {
	  this.isExpanded = !this.isExpanded;
	}

	toggleShowAll() {
		this.userInfo.beAdmin(this.showAll);
	}

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: window.location.origin
    });
  }
}
