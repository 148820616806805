import { BaseModel }				from "./BaseModel";
import { Component, Inject }		from "@angular/core";
import { HttpClient, HttpParams }	from "@angular/common/http";
import { Observable }				from "rxjs";
import { Position }					from "./Position";

// =============================================================================
export class JobCategory extends BaseModel {
	jobCategoryText:		string;
	positions:				Position[];

	dirty:					boolean = false;

	// =========================================================================
	public static fetch(http: HttpClient, jobCategoryId: number) {
        let params = new HttpParams()
            .set("jobCategoryId", jobCategoryId.toString())
        ;

		return http.get<JobCategory>("/api/jobcategory", {
			params: params
		});
	}

	// =========================================================================
	public static fetchMany(http: HttpClient) {
		return http.get<JobCategory[]>("/view/jobcategories");
	}

	// =========================================================================
	public static pack(categories: JobCategory[]) {
		let packed = [];

		categories.forEach(c => {
			packed.push(c);
		});

		return packed;
	}

	// =========================================================================
	public static save(http: HttpClient, categories: JobCategory[]): Observable<JobCategory[]> {
		let data = JobCategory.pack(categories);

		return http.put<JobCategory[]>("/view/jobcategories", data);
	}

	// =========================================================================
	public unpack(data: any) {
		Object.assign(this, data);

		if(this.positions) {
			this.positions.forEach((p, i) => this.positions[i] = new Position(p));
		}
	}
}
