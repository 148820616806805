import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { faCalendarTimes }						from "@fortawesome/free-solid-svg-icons";
import { HttpClient }							from "@angular/common/http";
import { Opportunity }							from "../models/Opportunity";
import { RequisitionView }						from "../views/RequisitionView";
import { SearchService }						from "../services/search.service";
import { SortService }							from "../services/sort.service";
import { Subscription }							from "rxjs";
import { UserInfoService }						from "../services/user-info.service";

@Component({
  selector: 'requisition-view',
  templateUrl: './requisition-view.component.html',
  styleUrls: ['./requisition-view.component.css']
})
export class RequisitionViewComponent implements OnInit {
	http:		HttpClient;
	reqView:	RequisitionView;
	private		searchSubscription: Subscription;
	private		sortSubscription: Subscription;
	private		userSubscription: Subscription;

	@Input()	filter:	boolean = false;
	@Input()	level: string;

	public faCalendarTimes = faCalendarTimes;

	// =========================================================================
	constructor(
		http: HttpClient,
		searchService: SearchService,
		sortService: SortService,
		private userInfo: UserInfoService
	) {
		this.http = http;

		this.userSubscription = userInfo.announceFilter$.subscribe(
			beingAdmin => {
				this.reqView.beAdmin(beingAdmin);

				this.reqView.fetch(this.http)
				.subscribe(
					resp => {
					},
					error => {
						console.log("Error:");
						console.log(error);
						alert("Communication error");
					}
				);
			}
		);

		this.searchSubscription = searchService.announceFilter$.subscribe(
			filter => {
				if(searchService.active) {
					this.reqView.applyFilter(searchService.opportunities);
				}
				else {
					this.reqView.clearFilter();
				}
			}
		);

		this.sortSubscription = sortService.announceFilter$.subscribe(
			sortColumn => {
				switch(sortColumn) {
					case "division":
						this.reqView.opportunities.sort((lhs, rhs) =>
							lhs.division.substring(5).localeCompare(rhs.division.substring(5))
						);

						break;

					case "division#":
						this.reqView.opportunities.sort((lhs, rhs) => {
							let lhsId = parseInt(lhs.division.replace(/ -.*$/, ""));
							let rhsId = parseInt(rhs.division.replace(/ -.*$/, ""));

							return lhsId - rhsId;
						});

						break;

					case "interviewer":
						this.reqView.opportunities.sort((lhs, rhs) =>
							lhs.interviewer.localeCompare(rhs.interviewer)
						);

						break;

					case "location":
						this.reqView.opportunities.sort((lhs, rhs) =>
							lhs.location.localeCompare(rhs.location)
						);

						break;

					case "manager":
						this.reqView.opportunities.sort((lhs, rhs) =>
							lhs.manager.localeCompare(rhs.manager)
						);

						break;

					case "openFTE":
						this.reqView.opportunities.sort((lhs, rhs) => {
							if(lhs.evergreen && !rhs.evergreen) {
								return -1;
							}
							else {
								return lhs.openFTE - rhs.openFTE;
							}
						});

						break;

					case "opportunityText":
						this.reqView.opportunities.sort((lhs, rhs) =>
							lhs.opportunityText.localeCompare(rhs.opportunityText)
						);

						break;

					case "reqNumber":
						this.reqView.opportunities.sort((lhs, rhs) =>
							lhs.reqNumber.localeCompare(rhs.reqNumber)
						);

						break;

					case "status":
						this.reqView.opportunities.sort((lhs, rhs) =>
							lhs.status.localeCompare(rhs.status)
						);

						break;

					case "supervisor":
						this.reqView.opportunities.sort((lhs, rhs) =>
							lhs.supervisor.localeCompare(rhs.supervisor)
						);

						break;
				}
			}
		);
	}

	// =========================================================================
	ngOnDestroy(): void {
		this.searchSubscription.unsubscribe();
		this.sortSubscription.unsubscribe();
	}

	// =========================================================================
	ngOnInit(): void {
		this.reqView = new RequisitionView();
		this.reqView.fetch(this.http)
		.subscribe(
			resp => {
			},
			error => {
				console.log("Error:");
				console.log(error);
				alert("Communication error");
			}
		);
	}

}
