import { AccordionService }						from "../services/accordion.service";
import { Applicant }							from "../models/Applicant";
import { Component, Inject, ViewEncapsulation }	from "@angular/core";
import { HRMHomeView }							from "../views/HRMHomeView";
import { HttpClient }							from "@angular/common/http";
import { Router, ActivatedRoute, ParamMap }		from "@angular/router";
import { Subscription }							from "rxjs";
import { UserInfoService }						from "../services/user-info.service";
import { faAddressCard }						from "@fortawesome/free-solid-svg-icons";

// =============================================================================
@Component({
	encapsulation: ViewEncapsulation.None,
	selector: "app-home",
	templateUrl: "home.component.html",
	styleUrls: ['home.component.css']
})
export class HomeComponent {
	public gotUser: boolean;
	public http: HttpClient;
	public myModel: HRMHomeView;
	public faAddressCard = faAddressCard;
	public level: string = null;
	public reqNumber: string;
	public userType: string = null;
	public view: string;

	private	accordionSubscription:	Subscription;
	private userSubscription:		Subscription;

	// =========================================================================
	constructor(
		http: HttpClient,
		private route: ActivatedRoute,
		userInfo: UserInfoService,
		private router: Router,
		private accordionService: AccordionService
	)
	{
		this.gotUser = false;
		this.http = http;

		this.userSubscription = userInfo.announceFilter$.subscribe(
			beingAdmin => {
				if(beingAdmin) {
					this.userType = "admin";
				}
				else {
					this.userType = "hrbp";
				}
			}
		);

		userInfo.fetch()
		.subscribe(
			resp => {
				this.userType = userInfo.role;

				switch(this.userType) {
					case "admin":	this.level = "HRBP";	break;
					case "hrbp":	this.level = "HRM";		break;
					default:		this.level = "REQ";		break;
				}

				this.gotUser = true;
			},
			error => {
				alert("Communication error");
			}
		);
	}

	// =========================================================================
	ngOnInit() {
		this.route.queryParams.subscribe(params => {
			this.reqNumber = params.reqNumber;

			if(this.reqNumber) {
				this.accordionService.getPath(this.reqNumber);
			}
		});

		if("hrm" == this.userType) {
			this.myModel = new HRMHomeView();
			this.myModel.fetch(this.http);
		}
	}

	// =========================================================================
	onViewChange(level) {
		this.level = level;
	}
}
