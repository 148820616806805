import { Component, Input, OnInit, ViewEncapsulation }	from "@angular/core";
import { HRBPHomeView }									from "../views/HRBPHomeView";
import { HttpClient }									from "@angular/common/http";
import { Interviewer }									from "../models/Interviewer";

// =============================================================================
@Component({
	encapsulation: ViewEncapsulation.None,
	selector: "hrbp-home",
	templateUrl: "./hrbp-home.component.html",
	styleUrls: ["./hrbp-home.component.css"]
})
export class HrbpHomeComponent implements OnInit {
	public hrbpHomeView:	HRBPHomeView;
	public http:			HttpClient;

	@Input() filter:		boolean = false;
	@Input() public hrbp:			Interviewer;

	// =========================================================================
	constructor(http: HttpClient) {
		this.http = http;
	}

	// =========================================================================
	ngOnInit() {
		this.hrbpHomeView = new HRBPHomeView();

		let promise = null;

		if(undefined === this.hrbp) {
			promise = this.hrbpHomeView.fetch(this.http);
		}
		else {
			promise = this.hrbpHomeView.fetch(this.http, this.hrbp.email);
		}

		promise
		.subscribe(
			response => {
			},
			error => {
				console.log("hrbp-home GET error:");
				console.log(error);
			}
		);
	}
}
