import { AdminHomeView }			from "../views/AdminHomeView";
import { Component, Input, OnInit }	from '@angular/core';
import { HttpClient }				from "@angular/common/http";

// =============================================================================
@Component({
  selector: 'admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {
	homeView:	AdminHomeView;
	http:		HttpClient;

	@Input()	filter:	boolean = false;

	// =========================================================================
	constructor(http: HttpClient) {
		this.http = http;
	}

	// =========================================================================
	ngOnInit() {
		this.homeView = new AdminHomeView();
		this.homeView.fetch(this.http);
	}
}
