import { Component, Input, OnInit, ViewEncapsulation }	from "@angular/core";
import { HRMHomeView }									from "../views/HRMHomeView";
import { HttpClient }									from "@angular/common/http";
import { Opportunity } 									from "../models/Opportunity";

// =============================================================================
@Component({
	encapsulation:	ViewEncapsulation.None,
	selector: "hrm-home",
	templateUrl: "./hrm-home.component.html",
	styleUrls: ["hrm-home.component.css"]
})
export class HrmHomeComponent implements OnInit {
	public http: HttpClient;
	public myModel: HRMHomeView;

	@Input() filter:	boolean = false;
  @Input() isStandalone:	boolean = false;
	@Input() hrm:		string;
	@Input() public		opportunities: Opportunity[] = [];

	// =========================================================================
	constructor(http: HttpClient) {
		this.http = http;
	}

	// =========================================================================
	ngOnInit() {
    if(this.isStandalone === false) { return; }

		this.myModel = new HRMHomeView();

		this.myModel.fetch(this.http, this.hrm)
		.subscribe(
			resp => {
				this.opportunities = this.myModel.opportunities;
			}
		);
	}
}
