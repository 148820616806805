import { BaseModel }	from "./BaseModel";
import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";
import { Probe }					from "./Probe";

// =============================================================================
export class Question extends BaseModel {
	id:				number = null;
	categoryId:		number = null;
	deleted:		boolean = null;
	probes:			Probe[] = [];
	published:		boolean = null;
	questionText:	string = null;
	required:		number = null;
	sortOrder:		number = null;

	// =========================================================================
	static pack(qst, categoryId): Question {
		let newQst = new Question();

		Object.assign(newQst, qst);

		newQst.id = newQst.id ? newQst.id : -1;
		newQst.categoryId = categoryId;
		newQst.deleted = newQst.deleted ? true : false;
		newQst.published = newQst.published ? true : false;
		newQst.sortOrder = newQst.sortOrder ? newQst.sortOrder : -1;

		newQst.probes = [];

		qst.probes.forEach(p => {
			newQst.probes.push(Probe.pack(p, newQst.id));
		});

		return newQst;
	}

	// =========================================================================
	public unpack(data: any) {
		Object.assign(this, data);

		if(this.probes) {
			this.probes.forEach((p, i) => this.probes[i] = new Probe(p));
		}
		else {
			this.probes = [];
		}
	}
}
