import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";
import { Answer }					from "../models/Answer";
import { Applicant }				from "../models/Applicant";
import { Category }					from "../models/Category";
import { Opportunity }				from "../models/Opportunity";
import { Score }					from "../models/Score";

// =========================================================================
export class InterviewView {
	public opportunities:	Opportunity[];

	// =========================================================================
	public fetch(http: HttpClient, email?: string) {
		let params = {};

		if(undefined !== email && "" !== email) {
			params = {
				params: {
					email:	email,
				}
			}
		}

		let promise = http.get<Opportunity[]>("/view/interview", params)

		promise.subscribe(result => {
				this.opportunities = [];

				result.forEach(r => {
					let opp = new Opportunity();
					opp.unpack(r);
					this.opportunities.push(opp);
				});
			},
			error => {
				console.error(error);

				alert("System error - contact your administrator.");
			}
		);

		return promise;
	}
}
