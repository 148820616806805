import { BaseModel }			from "./BaseModel";
import { Component, Inject }	from "@angular/core";
import { HttpClient }			from "@angular/common/http";
import { Probe }				from "./Probe";

// =============================================================================
export class Answer extends BaseModel {
	answerText:		string;
	interviewId:	number;
	questionId:		number;

	// =========================================================================
	public unpack(data: any) {
		Object.assign(this, data);
	}
}
