import { AccordionService }				from "../services/accordion.service";
import { Component, Input, OnInit } 	from '@angular/core';
import { faCalendar, faCalendarTimes }	from "@fortawesome/free-solid-svg-icons";
import { HRMsView }						from "../views/HRMsView";
import { HttpClient }					from "@angular/common/http";
import { Interviewer }					from "../models/Interviewer";
import { Subscription }					from "rxjs";

// =============================================================================
@Component({
  selector: 'hrbp-panel',
  templateUrl: './hrbp-panel.component.html',
  styleUrls: ['./hrbp-panel.component.css']
})
export class HrbpPanelComponent implements OnInit {
	public flashing:	boolean = false;
	public hrms:		HRMsView;
	public http:		HttpClient;

	public isCollapsed:	boolean = true;

	@Input() filter:	boolean = false;
	@Input() hrbp:		Interviewer;
	@Input() hrbpIndex:	number;

	public faCalendar = faCalendar;
	public faCalendarTimes = faCalendarTimes;

	private accordionService: AccordionService;
	private accordionSubscription: Subscription;

	// =========================================================================
	constructor(
		http: HttpClient,
		accordionService: AccordionService
	) {
		this.http = http;
		this.hrms = new HRMsView();

		this.accordionService = accordionService;
	}

	// =========================================================================
	flash() {
		let interval = 100;

		this.flashing = true;

		let flasher = setTimeout(() => {
			this.flashing = false;
		}, interval);
	}

	// =========================================================================
	ngOnDestroy() {
		this.accordionSubscription.unsubscribe();
	}

	// =========================================================================
	ngOnInit() {
		this.accordionSubscription = this.accordionService.announceFilter$.subscribe(
			reqPath => {
				if(this.accordionService.HRBP == this.hrbp.email) {
					this.isCollapsed = false;
				}
			}
		);
	}

	// =========================================================================
	toggle() {
		this.flash();
		this.isCollapsed = !this.isCollapsed;
	}
}
