import { BaseModel }			from "./BaseModel";
import { Component, Inject }	from "@angular/core";
import { HttpClient }			from "@angular/common/http";

// =============================================================================
export class Interviewer extends BaseModel {
	email:		string;
	name:		string;
	fte:		number;
	openFTE:	number;
	reqs:		number;

	stale:		false;

	// =========================================================================
	unpack(data: any) {
		Object.assign(this, data);
	}
}
