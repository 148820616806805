import { Component, EventEmitter, Input, OnInit, Output }	from "@angular/core";
import { SortService }										from "../services/sort.service";
import { Subscription } from 								"rxjs";
import { UserInfoService }									from "../services/user-info.service";

// =============================================================================
class SortSelector {
	sortColumn:		string;
	sortText:		string;
}

// =============================================================================
@Component({
  selector: "home-header",
  templateUrl: "./home-header.component.html",
  styleUrls: ["./home-header.component.css"]
})
export class HomeHeaderComponent implements OnInit {
	public		defaultLevel:		string = null;
	public		byLevel: 			string = null;
	public		searchFor:	 		string = null;
	public		showDisplayBy:		boolean = false;
	public		sortBy:				string = "reqNumber";
	public		userInfo:			UserInfoService;
	public		userSubscription:	Subscription;

	public		sortByMenu: SortSelector[] = [
		{ sortColumn: "reqNumber",			sortText: "Requisition Number" },
		{ sortColumn: "division",			sortText: "Division Name" },
		{ sortColumn: "division#",			sortText: "Division ID" },
		{ sortColumn: "interviewer",		sortText: "HR" },
		{ sortColumn: "location",			sortText: "Location" },
		{ sortColumn: "manager",			sortText: "HRBP" },
		{ sortColumn: "openFTE",			sortText: "Open FTEs" },
		{ sortColumn: "opportunityText",	sortText: "Position" },
		{ sortColumn: "supervisor",			sortText: "Supervisor" },
	];
		
	@Input()	level:			string;
	@Input()	view:			string;
	@Output()	viewChange = new EventEmitter<string>();
	@Output()	sortChange = new EventEmitter<string>();

	// =========================================================================
	constructor(private sortService: SortService, userInfo: UserInfoService) {
		this.userInfo = userInfo;

		this.showDisplayBy = userInfo.isHRM() ? false : true;

		this.userSubscription = userInfo.fetch().subscribe(
			userEvent => {
				this.showDisplayBy = userInfo.isHRM() ? false : true;
			}
		);
	}

	// =========================================================================
	levelText(): string {
		switch(this.defaultLevel) {
			case "HRBP":	return "HRBP";
			case "HRM":		return "HRM";
			default:		return "REQ";
		}
	}

	// =========================================================================
	// Use the first non-null level as the default non-REQ level
	ngOnChanges(changes) {
		if(null == changes.level.previousValue) {
			this.defaultLevel = changes.level.currentValue;
		}
	}

	// =========================================================================
	ngOnInit() {
	}

	// =========================================================================
	onLevelChange(level) {
		this.viewChange.emit(level);
	}

	// =========================================================================
	onSortChange() {
		this.sortService.sort(this.sortBy);
	}
}
