import { BaseModel }				from "./BaseModel";
import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";

// =============================================================================
export class Location extends BaseModel {
	id:				number;
	locationText:	string;

	// =========================================================================
	unpack(data: any) {
		Object.assign(this, data);
	}
}
