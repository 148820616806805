import { Component, Inject }	from "@angular/core";
import { HttpClient }			from "@angular/common/http";

// =============================================================================
export abstract class BaseModel {
	id: number;

	// =========================================================================
	constructor(data: any = null) {
		if(null !== data) {
			this.id = data.id;

			this.unpack(data);
		}
	}

	// =========================================================================
	public empty() {
		return null === this.id;
	}

	// =========================================================================
	abstract unpack(data: any): any
}
