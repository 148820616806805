import { JobCategoriesComponent }	from "./job-categories/jobcategories.component";
import { QuestionsComponent }		from "./questions/questions.component";
import { HomeComponent }			from "./home/home.component";

import { HttpClientModule }			from "@angular/common/http";
import { InterviewComponent }		from "./interview/interview.component";
import { NgModule }					from "@angular/core";
import { Routes, RouterModule }		from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";

const routes: Routes = [
	{ path: "",							component: HomeComponent, canActivate: [MsalGuard] },
	{ path: "home",						component: HomeComponent, canActivate: [MsalGuard] },
	{ path: "interview",				component: InterviewComponent, canActivate: [MsalGuard] },
	{ path: "jobcategories",			component: JobCategoriesComponent, canActivate: [MsalGuard] },
	{ path: "questions/:jobCatId",		component: QuestionsComponent, canActivate: [MsalGuard] },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
