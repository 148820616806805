import { HttpClient, HttpParams }	from "@angular/common/http";
import { Injectable }				from "@angular/core";
import { Subject }					from "rxjs";

// =============================================================================
// This is the service that opens accordion panels if I have a reqNumber
// query parameter in home.
@Injectable({
	providedIn: "root"
})
export class AccordionService {
	public	HRBP:		string;
	public	HRM:		string;
	public	ReqNumber:	string = null;

	private	announceFilter	= new Subject<string>();
	public	announceFilter$	= this.announceFilter.asObservable();

	// =========================================================================
	constructor(private http: HttpClient) {
	}

	// =========================================================================
	public getPath(reqnumber: string) {
		if(reqnumber) {
			this.http.get<any>("/api/requestpath", {
				params: {
					reqnumber:	reqnumber
				}
			})
			.subscribe(
				result => {
					this.HRBP =			result.hrbp;
					this.HRM =			result.hrm;
					this.ReqNumber =	result.reqnumber;

					this.announceFilter.next("reqpath");

					let numIterations = 24;

					let interval = setInterval(() => {
						this.announceFilter.next("reqpath");

						if(0 == --numIterations) {
							clearInterval(interval);
						}
					}, 100);
				},
				error => {
					console.error(error);
					alert("System error - contact your administrator");
				}
			);
		}
	}

	// =========================================================================
	public ping() {
		this.announceFilter.next("reqpath");
	}
}
