import { ActivatedRoute }					from "@angular/router";
import { BsModalRef, BsModalService }		from "ngx-bootstrap/modal";
import { Category }							from "../models/Category";
import {
	Component,
	OnInit,
	TemplateRef,
	ViewChild
}											from '@angular/core';
import {
	faAngleDoubleRight,
	faAngleDoubleDown,
	faAngleDoubleUp,
	faPlusSquare,
	faTrashAlt,
	faTrashRestore
}											from "@fortawesome/free-solid-svg-icons";
import { HttpClient }						from "@angular/common/http";
import { JobCategory }						from "../models/JobCategory";
import { Position }							from "../models/Position";
import { Probe }							from "../models/Probe";
import { Question }							from "../models/Question";

// =============================================================================
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {
	categories:				Category[] = [];
	currentPosId:			number = 0;
	faAngleDoubleDown		= faAngleDoubleDown;
	faAngleDoubleRight		= faAngleDoubleRight;
	faAngleDoubleUp			= faAngleDoubleUp;
	faPlusSquare			= faPlusSquare;
	faTrashAlt				= faTrashAlt;
	faTrashRestore			= faTrashRestore;
	http:					HttpClient;
	jobCatId:				number;
	jobCategory:			JobCategory;
	jobCategories:			JobCategory[] = [];
	positions:				Position[];
	showDeletedCategories:	boolean;
	tabIndex:				number = 0;

	@ViewChild("publishModal")	publishModal: TemplateRef<any>;

	// When deleting a category...
	public deleteCategoryModalRef:		BsModalRef;
	public deleteCategoryModalId:		number = 1;
	public sacrificialCatIdx:			number = null;
	public sacrificialCategory:			Category;

	// When deleting a question...
	public deleteQuestionModalRef:		BsModalRef;
	public deleteQuestionModalId:		number = 2;
	public sacrificialQstIdx:			number = null;
	public sacrificialQuestion:			Question;

	// When adding a new category
	public newCatIdx:					number = null;
	public newCat:						Category;
	public newCatErrorMsg:				string = null;
	public newCatInputType:				string = "questions";
	public newCatModalRef:				BsModalRef;
	public newCatModalId:				number = 3;

	// When publishing text changes
	public publishModalRef:				BsModalRef;
	public publishModalId:				number = 4;

	// When resetting ...
	public resetModalRef:				BsModalRef;
	public resetModalId:				number = 5;

	// =========================================================================
	constructor(
		http: HttpClient,
		private modalService: BsModalService,
		private route: ActivatedRoute
	) {
		this.http = http;
		this.jobCategory = new JobCategory();
	}

	// =========================================================================
	public addCategory() {
		console.log("Add category at " + this.newCatIdx);

		if(this.newCat.categoryText) {
			this.newCat.questions = [];

			if("comment" == this.newCatInputType) {
				let q = new Question();
				q.questionText = "";
				q.probes = [];

				this.newCat.questions.push(q);
			}
			else {
				for(let i = 0 ; i < 2 ; ++i) {
					let q = new Question();

					for(let j = 0 ; j < 3 ; ++j) {
						q.probes.push(new Probe());
					}

					this.newCat.questions.push(q);
				}
			}

			this.categories.splice(this.newCatIdx, 0, this.newCat);
			this.categories.forEach((c, i) => {
				c.sortOrder = i + 1;
			});

			console.log("Categories after add:");
			console.log(this.categories);

			this.closeAddCatModal();
		}
		else {
			this.newCatErrorMsg = "Category name is required";
		}
	}

	// =========================================================================
	public addQuestion(catIdx, qstIdx) {
		let newQst = new Question();

		for(let i = 0 ; i < 3 ; ++i) {
			newQst.probes.push(new Probe());
		}

		this.categories[catIdx].questions.splice(qstIdx + 1, 0, newQst);

		this.categories[catIdx].questions.forEach((q, i) =>
			q.sortOrder = 1 + i
		);
	}

	// =========================================================================
	public cloneJobCat(jobCatId) {
		console.log("Clone jobcat " + jobCatId);

		Category.clone(this.http, jobCatId, this.jobCatId)
		.subscribe(result => {
			this.categories = result;

			console.log("Cloned categories = ...");
			console.log(this.categories);
		});

		return false;
	}

	// =========================================================================
	public closeAddCatModal() {
		this.modalService.hide(this.newCatModalId);
	}

	// =========================================================================
	public closeDeleteCategoryModal() {
		this.modalService.hide(this.deleteCategoryModalId);
	}

	// =========================================================================
	public closeDeleteQuestionModal() {
		this.modalService.hide(this.deleteQuestionModalId);
	}

	// =========================================================================
	public closePublishModal() {
		this.modalService.hide(this.publishModalId);
	}

	// =========================================================================
	public closeResetModal() {
		this.modalService.hide(this.resetModalId);
	}

	// =========================================================================
	public deleteCategory(catIdx) {
		this.categories[catIdx].deleted = true;

		this.closeDeleteCategoryModal();
	}

	// =========================================================================
	public deleteQuestion(catIdx, qstIdx) {
		let question = this.categories[catIdx].questions[qstIdx];

		// If it's completely empty, really delete
		if(null == question.questionText ||
			0 == question.questionText.trim().length)
		{
			let hasProbes = false;

			question.probes.forEach(p => {
				hasProbes = hasProbes && 0 != p.probeText.trim().length;
			});

			if(hasProbes) {
				this.categories[catIdx].questions[qstIdx].deleted = true;
			}
			else {
				this.categories[catIdx].questions.splice(qstIdx, 1);
			}
		}
		// Otherwise, just mark deleted
		else {
			this.categories[catIdx].questions[qstIdx].deleted = true;
		}

		this.closeDeleteQuestionModal();
	}

	// =========================================================================
	// You can look but you better not touch!
	public displayQuestion(catIdx, qstIdx) {
		let qst = this.categories[catIdx].questions[qstIdx];

		return this.isaComment(qst) || qst.published;
	}

	// =========================================================================
	// You can edit this question
	public editQuestion(catIdx, qstIdx) {
		let qst = this.categories[catIdx].questions[qstIdx];

		return this.isaQuestion(qst) && !qst.published;
	}

	// =========================================================================
	private isaComment(question) {
		return	null == question.questionText ||
				(0 == question.questionText.length && 0 == question.probes.length)
		;
	}

	// =========================================================================
	private isaQuestion(question) {
		return 	null != question && 0 != question.probes.length;
	}

	// =========================================================================
	public moveCatDown(catIdx, last) {
		if(!last) {
			let floater = this.categories.splice(catIdx, 1);

			this.categories.splice(catIdx + 1, 0, floater[0]);
			this.categories.forEach((c, i) =>
				c.sortOrder = 1 + i
			);
		}
	}

	// =========================================================================
	public moveCatUp(catIdx, first) {
		if(!first) {
			let floater = this.categories.splice(catIdx, 1);

			this.categories.splice(catIdx - 1, 0, floater[0]);
			this.categories.forEach((q, i) =>
				q.sortOrder = 1 + i
			);
		}
	}

	// =========================================================================
	public moveQuestionDown(catIdx, qstIdx, last) {
		if(!last) {
			let floater = this.categories[catIdx].questions.splice(qstIdx, 1);

			this.categories[catIdx].questions.splice(qstIdx + 1, 0, floater[0]);
			this.categories[catIdx].questions.forEach((q, i) =>
				q.sortOrder = 1 + i
			);
		}
	}

	// =========================================================================
	public moveQuestionUp(catIdx, qstIdx, first) {
		if(!first) {
			let floater = this.categories[catIdx].questions.splice(qstIdx, 1);

			this.categories[catIdx].questions.splice(qstIdx - 1, 0, floater[0]);
			this.categories[catIdx].questions.forEach((q, i) =>
				q.sortOrder = 1 + i
			);
		}
	}

	// =========================================================================
	ngAfterViewInit(): void {
		console.log("publishModal = ...");
		console.log(this.publishModal);
	}

	// =========================================================================
	ngOnInit(): void {
		this.jobCatId = parseInt(this.route.snapshot.paramMap.get("jobCatId"))	;
		console.log("questions: jobCatId = " + this.jobCatId);

		JobCategory.fetch(this.http, this.jobCatId)
		.subscribe(result => {
			this.jobCategory = result;
		});

		JobCategory.fetchMany(this.http)
		.subscribe(result => {
			this.jobCategories = result;
			console.log("jobCategories = ...");
			console.log(this.jobCategories);
		});

		Category.fetchMany(this.http, this.jobCatId)
		.subscribe(result => {
			this.categories = result;
		});
	}

	// =========================================================================
	public openDeleteCategoryModal(template: TemplateRef<any>, catIdx) {
		this.sacrificialCatIdx = catIdx;
		this.sacrificialCategory = this.categories[catIdx];

		this.deleteCategoryModalRef = this.modalService.show(template, {
			id: this.deleteCategoryModalId,
			class: "modal-lg"
		});

		return false;
	}

	// =========================================================================
	public openDeleteQuestionModal(template: TemplateRef<any>, catIdx, qstIdx) {
		this.sacrificialCatIdx = catIdx;
		this.sacrificialQstIdx = qstIdx;
		this.sacrificialQuestion = this.categories[catIdx].questions[qstIdx];

		this.deleteQuestionModalRef = this.modalService.show(template, {
			id: this.deleteQuestionModalId,
			class: "modal-lg"
		});

		return false;
	}

	// =========================================================================
	public openAddCatModal(template: TemplateRef<any>, catIdx) {
		this.newCatIdx = catIdx;
		this.newCat = new Category();
		this.newCat.scorable = true;
		this.newCatErrorMsg = null;

		this.newCatModalRef = this.modalService.show(template, {
			id: this.newCatModalId,
			class: "modal-lg"
		});

		return false;
	}

	// =========================================================================
	public openPublishModal(template: TemplateRef<any>) {
		this.publishModalRef = this.modalService.show(template, {
			id: this.publishModalId,
			class: "modal-lg"
		});

		return false;
	}

	// =========================================================================
	public openResetModal(template: TemplateRef<any>) {
		this.resetModalRef = this.modalService.show(template, {
			id: this.resetModalId,
			class: "modal-lg"
		});

		return false;
	}

	// =========================================================================
	public onProbeFocus(evt) {
		console.log("onProbeFocus ...");
		console.log("evt = ...");
		console.log(evt);

		return false;
	}

	// =========================================================================
	public publish(template: TemplateRef<any>) {
		console.log("Publishing");

		if(this.warnBeforePublish()) {
			this.openPublishModal(template);
		}
		else {
			this.save();
		}
	}

	// =========================================================================
	public reset() {
		this.ngOnInit();

		this.closeResetModal();
	}

	// =========================================================================
	public restoreCategory(catIdx) {
		this.categories[catIdx].deleted = false;
	}

	// =========================================================================
	public restoreQuestion(catIdx, qstIdx) {
		this.categories[catIdx].questions[qstIdx].deleted = false;
	}

	// =========================================================================
	public save() {
		Category.saveMany(this.http, this.jobCatId, this.categories)
		.subscribe(resp => {
			this.categories = resp;
			this.closePublishModal();
		});
	}

	// =========================================================================
	selectPosition() {
		if(0 != this.currentPosId) {
			Category.fetchMany(this.http, this.currentPosId)
			.subscribe(result => {
				this.categories = result;
			});
		}
		else {
			this.categories = [];
		}
	}

	// =========================================================================
	showCategory(catIdx) {
		return this.showDeletedCategories || !this.categories[catIdx].deleted;
	}

	// =========================================================================
	showQuestion(catIdx, qstIdx) {
		let category = this.categories[catIdx];
		let question = category.questions[qstIdx];

		return this.showCategory(catIdx) && category.showDeleted || !question.deleted;
	}

	// =========================================================================
	// If any questions are currently inputs, warn before publishing
	public warnBeforePublish() {
		let warn = false;

		this.categories.forEach(cat => {
			cat.questions.forEach(quest => {
				if(!quest.published) {
					warn = true;
				}
			})
		});

		return warn;
	}
}
