import { Component, Input, OnInit }	from "@angular/core";
import { faSearch, faTimesCircle }	from "@fortawesome/free-solid-svg-icons";
import { HintService }				from "../services/hint.service";
import { SearchService }			from "../services/search.service";
import { Subject }					from "rxjs/Subject";

// =============================================================================
@Component({
	selector: "search-bar",
	templateUrl: "./search-bar.component.html",
	styleUrls: [ "./search-bar.component.css" ],
	providers: []
})
export class SearchBarComponent implements OnInit {
	public		faSearch = faSearch;
	public		faTimesCircle = faTimesCircle;

	results:		string[];
	searchTerm$ =	new Subject<string>();
	hideList =		true;

	@Input()	disabled;
	@Input()	searchFor;

	// =========================================================================
	constructor(
		private hintService: HintService,
		private searchService: SearchService)
	{
		this.hintService.search(this.searchTerm$)
			.subscribe(results => {
				this.results = [];
				Object.keys(results).forEach(key => this.results.push(results[key]));
			})
		;
	}

	// =========================================================================
	checkEnter(event) {
		if("Enter" == event.key) {
			setTimeout(() => this.hideList = true, 600);
			this.submit(event.target.value);
		}
	}

	// =========================================================================
	clearSearch() {
		this.searchFor = null;
		this.searchService.clear();
	}

	// =========================================================================
	ngOnInit(): void {
	}

	// =========================================================================
	onFocus() {
		this.hideList = false;
	}

	// =========================================================================
	onFocusOut() {
		setTimeout(() => this.hideList = true, 600);
	}

	// =========================================================================
	submit(value: string) {
		this.searchFor = value;
		this.searchService.search(value);
	}
}
