import { HttpClient, HttpParams }	from "@angular/common/http";
import { Injectable }				from "@angular/core";
import { Subject }					from "rxjs";

// =============================================================================
@Injectable({
  providedIn: "root"
})
export class SearchService {
	public applicants:		number[] = [];
	public opportunities:	number[] = [];

	public active:			boolean = false;

	http:	HttpClient;

	private	announceFilter  = new Subject<string>();
	public	announceFilter$ = this.announceFilter.asObservable();
	

	// =========================================================================
	constructor(http: HttpClient) {
		this.http = http;
	}

	// =========================================================================
	clear() {
		this.applicants = [];
		this.opportunities = [];

		this.active = false;

		this.announceFilter.next("filter");
	}

	// =========================================================================
	search(value: string) {
		if(0 < value.length) {
			this.active = true;
		}
		else {
			this.active = false;
		}

		let params = new HttpParams().set("search", value);

		let obsrv = this.http.get("/api/search", {
			params: params
		});

		obsrv.subscribe(
			resp => {
				this.applicants = [];
				this.opportunities = [];

				Object.keys(resp).map(k => {
					let r = resp[k];

					if("applicant" == r.entity) {
						this.applicants.push(r.entityId);
					}
					else if("opportunity" == r.entity) {
						this.opportunities.push(r.entityId);
					}
					else {
						alert("System error: entity " + r.entity + " not recognized");
					}
				});

				this.announceFilter.next("filter");

			},
			error => {
				alert("Communication error");
			}
		);

		return obsrv;
	}

}
