import { BaseModel }	from "./BaseModel";
import { Component, Inject }		from "@angular/core";
import { HttpClient, HttpParams }	from "@angular/common/http";
import { Applicant }				from "./Applicant";
import { Interview }				from "./Interview";
import { Location }					from "./Location";
import { Position }					from "./Position";
import { Score }					from "./Score";
import { Step }						from "./Step";
import { tap }						from "rxjs/operators";

// =============================================================================
export class Opportunity extends BaseModel {
	applicants:			Applicant[];
	division:			string;
	evergreen:			boolean;
	fte:				number;
	interviewer:		string;
	interviews:			Interview[];
	location:			string;
	locations:			Location[];
	manager:			string;
	openFTE:			number;
	opportunityText:	string;
	position:			Position = new Position();
	reqNumber:			string;
	status:				string;
	supervisor:			string;

	noshow:				boolean = false;
	stale:				boolean;

	// =========================================================================
	public fetch(http: HttpClient, opportunityid: number) {
		let self = this;

		let params = new HttpParams()
			.set("opportunityid", opportunityid.toString())
		;

		let obsrv = http.get<any>("/api/opportunity", {
			params: params
		});

		return obsrv.pipe(tap(
			resp => {
				self.unpack(resp);
			},
			error => {
				console.log("Opportunity fetch error = ...");
				console.log(error);
				alert("Communication error");
			}
		));
	}

	// =========================================================================
	scoreFor(appId: number): number | null {
		let score = 0;

		this.interviews.forEach(interview => {
			if(interview.applicantId == appId) {
				interview.scores.forEach(s => score += s.scoreValue);
			}
		});

		return 0 < score ? score : null;
	}

	// =========================================================================
	unpack(data: any) {
		Object.assign(this, data);

		if(this.applicants) {
			this.applicants.forEach((a, i) => this.applicants[i] = new Applicant(a));
		}
		else {
			this.applicants = [];
		}

		if(this.interviews) {
			this.interviews.forEach((n, i) => this.interviews[i] = new Interview(n));
		}
		else {
			this.interviews = [];
		}

		if(this.locations) {
			this.locations.forEach((n, i) => this.locations[i] = new Location(n));
			this.locations.sort((lhs, rhs) => lhs.locationText.localeCompare(rhs.locationText));
		}
		else {
			this.locations = [];
		}

		this.position = new Position(this.position);
	}
}
