import { Answer }					from "./Answer";
import { BaseModel }				from "./BaseModel";
import { Category }					from "./Category";
import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";

// =============================================================================
export class Score extends BaseModel {
	scoreValue:		number;
	interviewId:	number;
	categoryId:		number;
	scorable:		boolean;
	category:		Category;
	answers:		Answer[];

	// =========================================================================
	public unpack(data: any) {
		Object.assign(this, data);

		if(this.answers) {
			this.answers.forEach((a, i) => this.answers[i] = new Answer(a));
		}
	}
}
