import { BaseModel }				from "./BaseModel";
import { Component, Inject }		from "@angular/core";
import { HttpClient, HttpParams }	from "@angular/common/http";
import { Step }						from "./Step";
import { tap }						from "rxjs/operators";

// =============================================================================
export class Applicant extends BaseModel {
	id:					number;

	email:				string;
	currentDispStatus:	string;
	currentUKGStep:		string;
	firstName:			string;
	lastName:			string;
	score:				number;
	suffix:				string;
	primaryPhone:		string;
	secondaryPhone:		string;
	dateApplied:		Date;
	oppIds:				Array<number>;
	steps:				Array<Step>;

	noshow:				boolean = false;
	stale:				boolean;

	// =========================================================================
	public fetch(http: HttpClient, applicantid: number) {
		let params = new HttpParams()
			.set("applicantid", applicantid.toString())
		;

		let promise = http.get<any>("/api/applicant", {
			params: params
		});

		return promise.pipe(tap(
			resp => {
				this.unpack(resp);
			},
		));
	}

	// =========================================================================
	public fullName(): string {
		return this.lastName + ", " + this.firstName;
	}

	// =========================================================================
	public setStatus(http: HttpClient, oppId: number, status: string) {
		let params = new HttpParams()
			.set("applicantid", this.id.toString())
			.set("opportunityid", oppId.toString())
			.set("status", status)
		;

		let promise = http.patch<any>("/view/interviewinput", {}, {
			params: params
		});

		promise.subscribe(
			resp => {
				this.currentDispStatus = resp.status;
			},
			error => {
				console.log("Finalize error = ...");
				console.log(error);
				alert("Communication error");
			}
		);
	}

	// =========================================================================
	unpack(data: any) {
		Object.assign(this, data);

		this.dateApplied = new Date(this.dateApplied);

		if(this.steps) {
			this.steps.forEach((s, i) => this.steps[i] = new Step(s));
		}
		else {
			this.steps = [];
		}
	}
}
