import { AppComponent }					from "./app.component";
import { AppRoutingModule }				from "./app-routing.module";
import { BrowserAnimationsModule }		from "@angular/platform-browser/animations";
import { BrowserModule }				from "@angular/platform-browser";
import { BsDropdownModule }				from "ngx-bootstrap/dropdown";
import { CollapseModule }				from "ngx-bootstrap/collapse";
import { FontAwesomeModule }			from "@fortawesome/angular-fontawesome";
import { FormsModule }					from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS }				from "@angular/common/http";
import { DatePipe }						from "@angular/common";
import { ModalModule }					from "ngx-bootstrap/modal";
import { NgModule }						from "@angular/core";
import { TooltipModule }				from "ngx-bootstrap/tooltip";

import { AccordionService }				from "./services/accordion.service";
import { AdminHomeComponent }			from "./admin-home/admin-home.component";
import { HRMPanelComponent }			from "./hrm-panel/hrm-panel.component";
import { HintService }					from "./services/hint.service";
import { HomeComponent }				from "./home/home.component";
import { HomeHeaderComponent }			from "./home-header/home-header.component";
import { HrbpHomeComponent }			from "./hrbp-home/hrbp-home.component";
import { HrbpPanelComponent }			from "./hrbp-panel/hrbp-panel.component";
import { HrmHomeComponent }				from "./hrm-home/hrm-home.component";
import { HttpConfigInterceptor }		from "./interceptor/httpconfig.interceptor";
import { JobCategoriesComponent }		from "./job-categories/jobcategories.component";
import { InterviewComponent }			from "./interview/interview.component";
import { NavMenuComponent }				from "./nav-menu/nav-menu.component";
import { OpportunityPanelComponent }	from "./opportunity-panel/opportunity-panel.component";
import { PhonePipe }					from "./pipes/phone.pipe";
import { QuestionsComponent }			from "./questions/questions.component";
import { RequisitionViewComponent } 	from "./requisition-view/requisition-view.component";
import { SearchBarComponent } 			from "./search-bar/search-bar.component";
import { SearchService }				from "./services/search.service";
import { SortService }					from "./services/sort.service";
import { UserInfoService }				from "./services/user-info.service";
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";

// =============================================================================

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
	declarations: [
		AdminHomeComponent,
		AppComponent,
		HRMPanelComponent,
		HomeComponent,
		HomeHeaderComponent,
		HrbpHomeComponent,
		HrbpPanelComponent,
		HrmHomeComponent,
		InterviewComponent,
		JobCategoriesComponent,
		NavMenuComponent,
		OpportunityPanelComponent,
		PhonePipe,
		QuestionsComponent,
		RequisitionViewComponent,
		SearchBarComponent,
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		BsDropdownModule.forRoot(),
		CollapseModule.forRoot(),
		ModalModule.forRoot(),
		FontAwesomeModule,
		FormsModule,
		HttpClientModule,
		TooltipModule.forRoot(),
    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId: 'cbcd31d8-e321-4946-a10c-ec8068d4081e',
        authority: 'https://login.microsoftonline.com/ad3b1983-bb28-4abd-b3c8-e2cda805da64',
        redirectUri: window.location.origin
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['api://cbcd31d8-e321-4946-a10c-ec8068d4081e/Data.All']
      }
  }, {
    interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
    protectedResourceMap: new Map([
        [`${window.location.origin}/api/*`, ['api://cbcd31d8-e321-4946-a10c-ec8068d4081e/Data.All']],
        [`${window.location.origin}/view/*`, ['api://cbcd31d8-e321-4946-a10c-ec8068d4081e/Data.All']],
    ])
  }),
	],
	providers: [
		AccordionService,
		DatePipe,
		HintService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
		SearchService,
		SortService,
		UserInfoService,
    MsalGuard,
	],
	bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
