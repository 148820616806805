import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
	HttpHandler,
	HttpEvent,
	HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
	static outstandingRequests = 0;

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		++HttpConfigInterceptor.outstandingRequests;

		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if(event instanceof HttpResponse) {
					--HttpConfigInterceptor.outstandingRequests;
				}

				return event;
			}));
	}
}
