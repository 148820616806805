import { BsModalRef, BsModalService }		from "ngx-bootstrap/modal";
import { BsDropdownModule }					from "ngx-bootstrap/dropdown";
import { Category }							from "../models/Category";
import { Component, OnInit, TemplateRef }	from '@angular/core';
import { faTimes, faTimesCircle }			from "@fortawesome/free-solid-svg-icons";
import { HttpClient }						from "@angular/common/http";
import { JobCategory }						from "../models/JobCategory";
import { Position }							from "../models/Position";

// =============================================================================
@Component({
  selector: 'app-jobcategories',
  templateUrl: './jobcategories.component.html',
  styleUrls: ['./jobcategories.component.css']
})
export class JobCategoriesComponent implements OnInit {
	public faTimesCircle = faTimesCircle;
	public faTimes = faTimes;

	public availableJobs:		Position[] = [];
	public jobCategories:		JobCategory[] = [];
	public http:				HttpClient;

	public newJob:				Position = new Position();
	public newJobCategoryIdx:	number;
	public newJobModalRef:		BsModalRef;
	public newJobModalId:		number = 1;

	// =========================================================================
	constructor(http: HttpClient, private modalService: BsModalService) {
		this.http = http;
	}

	// =========================================================================
	addNewJob() {
		console.log("addNewJob...");
		console.log(this.newJob);

		this.newJob.dirty = true;
		this.selectJob(this.newJobCategoryIdx, this.newJob);

		this.closeNewJobModal();
	}

	// =========================================================================
	cancelEdit() {
		this.ngOnInit();
	}

	// =========================================================================
	cancelNewJob() {
		console.log("cancelNewJob...");
		this.closeNewJobModal();
	}

	// =========================================================================
	closeNewJobModal() {
		this.modalService.hide(this.newJobModalId);
	}

	// =========================================================================
	createJob(catidx) {
		console.log("Create new job for category " + catidx);

		let job = new Position();

		job.dirty = true;

//		this.jobCategories[catidx].positions.push(job);
//		this.jobCategories[catidx].positions.sort((lhs, rhs) => {
//			return lhs.jobCode.localeCompare(rhs.jobCode);
//		});

		return false;
	}

	// =========================================================================
	editQuestions(categoryId) {
		console.log("editQuestions(" + categoryId + ")");
	}

	// =========================================================================
	ngOnInit(): void {
		this.http.get<Position[]>("/view/availablejobs")
		.subscribe(result => {
			this.availableJobs = result;
		});

		JobCategory.fetchMany(this.http)
		.subscribe(result => {
			this.jobCategories = result;
		});
	}

	// =========================================================================
	openNewJobModal(template: TemplateRef<any>, catIdx: number) {
		this.newJob = new Position();

		this.newJobCategoryIdx = catIdx;

		this.newJobModalRef = this.modalService.show(template, {
			class:	"modal-lg",
			id:		this.newJobModalId,
		});

		return false;
	}

	// =========================================================================
	rmvJob(catidx, posidx) {
		if(1 < this.jobCategories[catidx].positions.length) {
			let orphan = this.jobCategories[catidx].positions.splice(posidx, 1);
			orphan[0].dirty = true;

			this.availableJobs.push(orphan.shift());
		}
	}

	// =========================================================================
	save() {
		JobCategory.save(this.http, this.jobCategories)
		.subscribe(resp => {
			console.log("Save returns ...");
			console.log(resp);
		});
	}

	// =========================================================================
	selectJob(catidx, job) {
		let jobCode = job.jobCode;
		job.dirty = true;

		job.jobCategoryId = this.jobCategories[catidx].id;

		this.jobCategories[catidx].positions.push(job);
		this.jobCategories[catidx].positions.sort((lhs, rhs) => {
			return lhs.jobCode.localeCompare(rhs.jobCode);
		});

		this.availableJobs = this.availableJobs.filter(job => job.jobCode != jobCode);

		return false;
	}
}
