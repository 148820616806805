import { AccordionService }				from "../services/accordion.service";
import { Applicant } 					from "../models/Applicant";
import { BsModalRef, BsModalService }	from "ngx-bootstrap/modal";

import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewEncapsulation }
	from '@angular/core';

import { faAddressCard, faCalendarCheck, faCalendar, faCalendarTimes, faUserMinus, faUserPlus }
	from "@fortawesome/free-solid-svg-icons";

import { HttpClient }					from "@angular/common/http";
import { Interviewer }					from "../models/Interviewer";
import { Opportunity } 					from "../models/Opportunity";
import { Router }						from "@angular/router";
import { SearchService }				from "../services/search.service";
import { Subscription }					from "rxjs";

// =============================================================================
@Component({
	encapsulation:	ViewEncapsulation.None,
	selector: 'opportunity-panel',
	templateUrl: 'opportunity-panel.component.html',
	styleUrls: ['opportunity-panel.component.css']
})
export class OpportunityPanelComponent implements OnInit {
	private		searchService: SearchService;
	private		searchSubscription: Subscription;

	public colNames: string[] = [
		"Applicant",
		"Email",
		"Date Applied",
		"Status",
	];
	public faAddressCard = faAddressCard;
	public faCalendarCheck = faCalendarCheck;
	public faCalendar = faCalendar;
	public faCalendarTimes = faCalendarTimes;
	public faUserMinus = faUserMinus;
	public faUserPlus = faUserPlus;
	public http: HttpClient;

	public isCollapsed = true;
	public loading = true;

	private accordionService: AccordionService;
	private accordionSubscription: Subscription;

	@Input() filter: boolean = false;
	@Input() hrm: string;
	@Input() opportunity: Opportunity;
	@Input() showInterviewers: boolean = true;

	// =========================================================================
	constructor(
		http: HttpClient,
		searchService: SearchService,
		private modalService: BsModalService,
		private router: Router,
		accordionService: AccordionService
	) {
		this.searchService = searchService;
		this.http = http;

		this.accordionService = accordionService;

		this.searchSubscription = this.searchService.announceFilter$.subscribe(
			filter => {
				this.runSearch();
			}
		);
	}

	// =========================================================================
	appRowClass(app: Applicant) {
		if("Apply" == app.currentUKGStep) {
			return {
				"text-secondary":	true
			};
		}
		else {
			return {
				"clickable-app-row":	true
			};
		}
	}

	// =========================================================================
	gotoInterview(app: Applicant, opp: Opportunity) {
		if("Apply" != app.currentUKGStep) {
			this.router.navigate([ "/interview" ], {
				queryParams: {
					appid:			app.id,
					interviewer:	this.hrm,
					oppid:			opp.id
				}
			});
		}
	}

	// =========================================================================
	locationList() {
		let list = this.opportunity.locations.map(l => l.locationText);
		return list.join(", ");
	}

	// =========================================================================
	ngOnDestroy(): void {
		this.accordionSubscription.unsubscribe();
		this.searchSubscription.unsubscribe();
	}

	// =========================================================================
	ngOnInit() {
		this.accordionSubscription = this.accordionService.announceFilter$.subscribe(
			reqPath => {
				if(this.accordionService.ReqNumber == this.opportunity.reqNumber) {
					this.getCandidates(0);
					this.isCollapsed = false;
				}
			}
		);
	}

	// =========================================================================
	getCandidates(oppid: number) {
		if(this.isCollapsed && 0 == this.opportunity.applicants.length) {
			this.loading = true;

			this.http.get<Applicant[]>("/view/homeapplicants", {
				params: {
					oppid:	this.opportunity.id.toString()
				},
			})
			.subscribe(
				result => {
					result.forEach(app => {
						let newApp = new Applicant();
						newApp.unpack(app);

						this.opportunity.applicants.push(newApp);
					});

					this.runSearch();

					this.loading = false;
				},
				error => {
					console.log(error);

					this.loading = false;
				}
			);
		};

		this.isCollapsed = !this.isCollapsed;
	}

	// =========================================================================
	runSearch() {
		this.opportunity.applicants.forEach(a => {
			if(this.searchService.active) {
				a.noshow = !this.searchService.applicants.includes(a.id);
			}
			else {
				a.noshow = false;
			}
		});
	}
}
