import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";
import { Answer }					from "../models/Answer";
import { Applicant }				from "../models/Applicant";
import { Category }					from "../models/Category";
import { Opportunity }				from "../models/Opportunity";
import { Position }					from "../models/Position";
import { Score }					from "../models/Score";
import { Step }						from "../models/Step";
import { tap }						from "rxjs/operators";

// =========================================================================
export class RequisitionView {
	private beingAdmin:		boolean = false;
	public opportunities:	Opportunity[];

	// =========================================================================
	construct() {
		this.opportunities = new Array();
	}

	// =========================================================================
	public applyFilter(ids: number[]) {
		this.opportunities.forEach(o => {
			if(ids.includes(o.id)) {
				o.noshow = false;
			}
			else {
				o.noshow = true;
			}
		});
	}

	// =========================================================================
	public beAdmin(yesNo: boolean) {
		this.beingAdmin = yesNo;
	}

	// =========================================================================
	public clearFilter() {
		this.opportunities.forEach(o => {
			o.noshow = false;
		});
	}

	// =========================================================================
	public fetch(http: HttpClient, email?: string) {
		let params = {
			params: {}
		};

		if(undefined !== email) {
			params.params["interviewers"] = email;
		}

		if(true == this.beingAdmin) {
			params.params["beadmin"] = true;
		}

		let promise = http.get<Opportunity[]>("/view/requisition", params);

		return promise.pipe(tap(
			result => {
				this.opportunities = new Array();

				result.forEach(oppPlain => {
					this.opportunities.push(new Opportunity(oppPlain));
				});
			},
			error => {
				console.error(error);

				alert("System error - contact your administrator.");
			}
		));
	}
}
