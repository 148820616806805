import { BaseModel }	from "./BaseModel";
import { Component, Inject }		from "@angular/core";
import { HttpClient }				from "@angular/common/http";

// =============================================================================
export class Probe extends BaseModel {
	probeText:		string;
	sortOrder:		number;
	deleted:		boolean;
	questionId:		number;

	// =========================================================================
	static pack(probe, questionId): Probe {
		let newProbe = new Probe();

		Object.assign(newProbe, probe);

		newProbe.id = newProbe.id ? newProbe.id : -1;
		newProbe.questionId = questionId;
		newProbe.deleted = newProbe.deleted ? true : false;

		return newProbe;
	}

	// =========================================================================
	public unpack(data: any) {
		Object.assign(this, data);
	}
}
