import { Injectable }	from '@angular/core';
import { Subject }		from "rxjs";

// =============================================================================
@Injectable({
  providedIn: 'root'
})
export class SortService {
	private	announceFilter  = new Subject<string>();
	public	announceFilter$ = this.announceFilter.asObservable();

	// =========================================================================
	constructor() {
	}

	// =========================================================================
	sort(column: string) {
		this.announceFilter.next(column);
	}
}
